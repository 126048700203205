import {getQueryParam} from '../../utils/url';

class GtmListing {
  constructor() {
    this.fireImpression = this.fireImpression.bind(this);
    this.selectItemEcomEvent = this.selectItemEcomEvent.bind(this);
    this.selectCategoryEcomEvent = this.selectCategoryEcomEvent.bind(this);
    this.viewCategoryEcomEvent = this.viewCategoryEcomEvent.bind(this);
    document.addEventListener('DOMContentLoaded', this.fireImpression);
    document.addEventListener('DOMContentLoaded', this.viewCategoryEcomEvent);
    $(document).on('click', '.pc-ProductCard-link', this.selectItemEcomEvent);
    $(document).on('click', '.pc-CatCard-link', this.selectCategoryEcomEvent);
  }

  // Private

  fireImpression() {
    if ($('.pc-Styles').length && ProductCatalog.Styles.cards().length > 0) {
      this.listingImpression();
      this.listingEcomEvent();
    } else {
      setTimeout(() => this.fireImpression(), 1000);
    }
  }

  toSwatch(e) {
    const swatch = $(e.target);
    if (swatch.is('.pc-Swatch')) {
      return swatch;
    }
    return swatch.closest('.pc-Swatch');
  }

  listingImpression() {
    const list = this.listingImpressionName();
    const cards = ProductCatalog.Styles.cards().slice(0, 9).toArray();
    for (let index = 0; index < cards.length; index++) {
      const card = cards[index];
      const data = $(card).find('.pc-ProductCard-dataLayer').data();
      if (data) {
        data.position = index + 1;
        data.list = list;
        ProductCatalog.gtmEvent(
          'listingImpression',
          'listingsImpression',
          data
        );
      }
    }
    return undefined;
  }

  listingImpressionName() {
    if (ProductCatalog.Styles.isSearchResults()) {
      return 'Search Results';
    } else if (ProductCatalog.Styles.isCustom()) {
      return 'Listing Custom';
    } else {
      return 'Listing';
    }
  }

  listingEcomEvent() {
    const cards = ProductCatalog.Styles.cards().slice(0, 9).toArray();
    const category_tree = $('meta[property=categoryinfo]').attr(
      'category_tree'
    );
    const items = cards.map((card, index) => {
      return {
        item_id: parseInt(card.dataset['styleId']),
        item_name: card.dataset['styleName'],
        index: index + 1,
        item_brand: card.dataset['brandName'],
        item_category: card.dataset['itemCategory'],
        item_category2: card.dataset['itemCategory2'],
        item_category3: card.dataset['itemCategory3']
      };
    });
    const ecommerce = {
      item_list_id: parseInt($('meta[property=categoryinfo]').prop('id')),
      item_list_name: category_tree,
      items: items
    };

    ProductCatalog.gtmEcomEvent('view_item_list', {
      search_term: getQueryParam('keyword'),
      ecommerce
    });

    ProductCatalog.gtmEcomEvent('header_search_results', {
      search_term: getQueryParam('keyword'),
      search_header_v7: getQueryParam('searchType'),
      search_location: window.location.href
    });
    return undefined;
  }

  selectItemEcomEvent(e) {
    const $card = $(e.currentTarget).parents('.pc-ProductCard');
    const data = $card.find('.pc-ProductCard-ecomDataLayer').data();
    if (data) {
      const item = data.items[0];
      // Position of current card on the page
      item.index = $('.pc-ProductCard').index($card) + 1;
      item.item_list_name = this.listingImpressionName();
      const ecommerce = {
        item_list_id: parseInt($('meta[property=categoryinfo]').prop('id')),
        item_list_name: $('meta[property=categoryinfo]').attr('category_tree'),
        items: [item]
      };
      ProductCatalog.gtmEcomEvent('select_item', {
        search_term: getQueryParam('keyword'),
        ecommerce
      });
    }
    return undefined;
  }

  selectCategoryEcomEvent(e) {
    const $card = $(e.currentTarget).parents('.pc-CatCard');
    const data = $card.find('.pc-CatCard-ecomDataLayer').data();

    if (data) {
      const {promotion} = data;
      const eventData = {
        ...promotion,
        items: promotion.items.map((item) => {
          return {
            item_id: String(item.item_id),
            item_name: String(item.item_name)
          };
        })
      };

      ProductCatalog.gtmEcomEvent('select_promotion', eventData);
    }
  }

  viewCategoryEcomEvent() {
    const elements = document.querySelectorAll('.pc-CatCard');
    const publishedEvents = [];
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const dataSet = entry.target.querySelector(
              '.pc-CatCard-ecomDataLayer'
            )?.dataset;
            const promotion = dataSet ? JSON.parse(dataSet.promotion) : null;
            if (
              promotion &&
              !publishedEvents.includes(promotion.promotion_id)
            ) {
              publishedEvents.push(promotion.promotion_id);
              const eventData = {
                ...promotion,
                items: promotion.items.map((item) => {
                  return {
                    item_id: String(item.item_id),
                    item_name: String(item.item_name)
                  };
                })
              };

              ProductCatalog.gtmEcomEvent('view_promotion', eventData);
            }
          }
        });
      },
      {threshold: 0.6}
    );
    elements.forEach((elem) => observer.observe(elem));
  }
}

ProductCatalog.GtmListing = new GtmListing();
